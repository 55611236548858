"use client";
import React from "react";
import "./OrgList.scss";
import { Organization } from "../../../../api/organization";

export default function OrgListItem(props: OrgListItemProps) {
  const { organization } = props;

  return (
    <button
      onClick={() => props.onSelectOrg(organization)}
      className="org-list-item"
    >
      <div className="org-list-item-info">
        <span className="org-list-item-info-category org-category-badge">
          {organization.category?.name}
        </span>
        <span className="org-list-item-info-name">{organization.name}</span>

        <span className="org-list-item-info-headline">
          {organization.headline}
        </span>
      </div>

      <div className="org-list-item-logo">
        <img src={organization.logo} />
      </div>
    </button>
  );
}

interface OrgListItemProps {
  organization: Organization;

  onSelectOrg: (organization: Organization) => void;
}
