export function saveUser(user: any) {
  try {
    const serializedUser = JSON.stringify(user);
    localStorage.setItem("user", serializedUser);
  } catch {
    // ignore write errors
  }
}

export function savePhone(phone: string) {
  try {
    localStorage.setItem("phone", phone);
  } catch {
    // ignore write errors
  }
}

export function getUser() {
  try {
    const serializedUser = localStorage.getItem("user");
    if (serializedUser === null) {
      return undefined;
    }
    return JSON.parse(serializedUser);
  } catch {
    return undefined;
  }
}

export function getPhone() {
  try {
    const phone = localStorage.getItem("phone");
    if (phone === null) {
      return undefined;
    }
    return phone;
  } catch {
    return undefined;
  }
}

export function removeUser() {
  localStorage.removeItem("user");
}

export function getToken() {
  const user = getUser();
  return user ? user.uid : undefined;
}
