import { useState } from "react";
import "./RouteCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandsHoldingChild,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import FullModal from "../../../../components/FullModal/FullModal";
import Wallet from "../../../wallet/page";
import Marketplace from "../../../orgs/page";

export default function RouteCards(props: any) {
  const [selectedRoute, setSelectedRoute] = useState<ISelectedRoute>(null);

  const openModal = (selectedRoute: ISelectedRoute) => {
    setSelectedRoute(selectedRoute);
  };

  const closeModal = () => {
    setSelectedRoute(null);
  };

  return (
    <>
      <div className="route-cards-container">
        <div className="route-card" onClick={() => openModal("ongs")}>
          <FontAwesomeIcon color="#56BC6C" icon={faHandsHoldingChild} />
          <span>ONGs</span>
        </div>
        <div className="route-card" onClick={() => openModal("wallet")}>
          <FontAwesomeIcon color="#56BC6C" icon={faWallet} />
          <span>Carteira</span>
        </div>
      </div>

      {selectedRoute === "ongs" && (
        <FullModal
          title="ONGs"
          description="Veja aqui as ONGs que estão esperando sua doação, clique em uma delas para ver mais detalhes"
          closeModal={closeModal}
          order={{
            id: "org",
            sequence: 0,
          }}
        >
          <Marketplace />
        </FullModal>
      )}
      {selectedRoute === "wallet" && (
        <FullModal
          title="Carteira"
          description="Veja as transações e investimentos que você já fez"
          closeModal={closeModal}
          order={{
            id: "org",
            sequence: 0,
          }}
        >
          <Wallet />
        </FullModal>
      )}
    </>
  );
}

type ISelectedRoute = "ongs" | "wallet" | null;
