import { toReal } from "../../helpers/formatters";
import "./BalanceCard.scss";
import Skeleton from "react-loading-skeleton";

export default function BalanceCard(props: BalanceCardProps) {
  return (
    <div className="balance-card">
      <span className="balance-card-title">Saldo disponível</span>
      <span className="balance-card-amount">
        {props.balance ? toReal(props.balance || 0) : <Skeleton />}
      </span>
    </div>
  );
}

interface BalanceCardProps {
  balance?: number;
}
