import { Organization } from "./organization";
import { request } from "./req";

export type Transaction = {
  id: string;
  created_at: string;
  date?: string;
  amount: string;
  type: TransactionType;
  organization?: Organization;
};

export type TransactionType = "deposit" | "investment" | undefined;

export function listTransactions(): Promise<Transaction[]> {
  return request("get", "transactions");
}

export function invest(
  organizationId: string,
  amount: number
): Promise<Transaction> {
  return request("post", "transactions/invest", {
    organizationId,
    amount,
  });
}
