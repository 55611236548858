export const PRIMARY = "#56BC6C"; // 81cc00
export const PRIMARY_DARK = "#3f8e4d";
export const SECONDARY = "#5258B5";
export const TERTIARY = "#CEFD85";
export const TERTIARY_DARK = "#b6ee67";
export const LIGHT_BLUE = "#589bca";
export const WHITE = "#fff";
export const BACKGROUND = "#f0f0f2";
export const LIGHT_BACKGROUND = "#f7f7fa";
export const GRAY = "#666";
export const GRAY2 = "#00adcc";
export const PRIMARY_GREEN = "#c1ec45";
