import moment from "moment-timezone";

export function toReal(num: number | string) {
  const val = parseFloat(String(num));
  return val.toLocaleString("pt-br", {
    // style: "currency",
    minimumFractionDigits: 2,
    currency: "BRL",
  });
}

export function realToNumber(num: string) {
  return parseFloat(
    num.replace("R$", "").replace(".", "").replace(",", ".").replace(" ", "")
  );
}

export function readableDate(date?: any, hours: boolean = false) {
  console.log(date);
  if (hours) {
    return moment(date).tz("America/Sao_Paulo").format("DD/MM/YY HH:mm");
  }
  return moment(date).tz("America/Sao_Paulo").format("DD/MM/YYYY");
}

export function formatCPF(rawCpf: string) {
  const cpf = rawCpf.replace(/[^\d]/g, "");
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}
