import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "./Profile.scss";
import Skeleton from "react-loading-skeleton";
import { User, getBalance } from "../../api/users";
import { removeUser } from "../../app/storage";
import { useNavigate } from "react-router";

export default function Profile(props: any) {
  const [user, setUser] = useState<User | undefined>(undefined);

  const navigate = useNavigate();

  const logout = () => {
    removeUser();
    navigate("/login", { replace: true} );
  };

  useEffect(() => {
    getBalance().then((balance) => {
      setUser(balance);
      console.log("USER: ", balance);
    });
  }, []);

  return (
    <div className="user-profile">
      <div className="user-profile-title">
        <div className="user-profile-icon">
          <FontAwesomeIcon icon={faUser} color="white" />
        </div>
        <div className="user-profile-info">
          <h2 className="user-profile-name">
            {user ? user.name : <Skeleton />}
          </h2>
        </div>
      </div>

      <div className="user-profile-description">
        <span className="user-profile-description-data">
          <span className="user-profile-description-data-title">Telefone</span>
          {user ? (
            user.phone
          ) : (
            <Skeleton style={{ marginBottom: 5 }} width={150} height={20} />
          )}
        </span>

        <span className="user-profile-description-data">
          <span className="user-profile-description-data-title">CPF</span>
          {user ? (
            user.cpf
          ) : (
            <Skeleton style={{ marginBottom: 5 }} width={150} height={20} />
          )}
        </span>
      </div>

      <div className="logout-button-container">
        <button onClick={logout}>DESCONECTAR</button>
      </div>
    </div>
  );
}
