import { getPhone, getToken } from "../app/storage";

const baseUrl = "https://default-mbbdgak5xq-uc.a.run.app/";
// const baseUrl = "http://127.0.0.1:5001/giv-app-123/us-central1/default/";

export const request = async (
  method: string,
  url: string,
  data?: any,
  options?: any
) => {
  try {
    const resp = await fetch(baseUrl + url, {
      ...options,
      method: method,
      mode: "cors",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
        authorization: getToken() || "",
        phone: getPhone() || "",
      },
      body: data ? JSON.stringify(data) : undefined,
    }).then((res) => {
      if (!res.ok) {
        window.location.href = "/login";
        return;
      }

      return res.json();
    });
    return resp;
  } catch (e) {
    console.error("> error", e);
    return e;
  }
};
