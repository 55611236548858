import React, {forwardRef, ForwardedRef} from "react";
import IMask from "imask";
import {TextFieldProps} from "@mui/material";
import TextField from "@mui/material/TextField";

type MaskedTextFieldProps = {
  mask:  any // string | RegExp | (string | RegExp)[]; string | { mask: string; }[]//
} & TextFieldProps;

// eslint-disable-next-line react/display-name
const MaskedTextField = forwardRef(
  (props: MaskedTextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {mask, ...otherProps} = props;

    // Create a ref for the input element
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    // Initialize the IMask instance
    React.useEffect(() => {
      if (inputRef.current) {
        const maskInstance = IMask(inputRef.current, {
          mask: mask,
        });

        // Cleanup when the component unmounts
        return () => {
          maskInstance.destroy();
        };
      }
    }, [mask]);

    return <TextField {...otherProps} inputRef={inputRef}/>;
  }
);

export default MaskedTextField;
