import { Portfolio } from "./portfolio";
import { request } from "./req";
import { Transaction } from "./transaction";

export type User = {
  id: string;
  name: string;
  cpf: string;
  phone: string;
  balance: number;
  invested: string;
  investments: Transaction[];
  portfolio: Portfolio[];
};

export const getBalance = async () => {
  const response = await request("get", `users`);
  return response;
};

export const createUser = async (name: string, cpf: string, phone: string, email?: string) => {
  const response = await request("post", `users`, {
    name,
    cpf,
    phone,
    email,
  });

  return response;
};
