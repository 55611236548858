import { useState } from "react";
import "./PortfolioItem2.scss";
import { Portfolio } from "../../../../api/portfolio";
import { Organization } from "../../../../api/organization";
import FullModal from "../../../../components/FullModal/FullModal";
import OrgDetails from "../../../orgs/components/OrgDetails/OrgDetails";
import { toReal } from "../../../../helpers/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

type PortfolioItemProps = {
  portfolio: Portfolio;
};

const getPerformance = (organization: Organization) => {
  return organization.performance
    ?.sort((a, b) => b.created_at.localeCompare(a.created_at))
    .slice(0, 3);
};

const getChartColor = (chartData: number[]): InterfaceColors => {
  const adjustment = 0.0;

  return chartData.length > 1
    ? chartData[0] > adjustment
      ? "#4caf50"
      : "#f44336"
    : "#324d8d";
};

const buildColors = (
  organization: Organization
): [number[], InterfaceColors] => {
  const performance = getPerformance(organization);
  const chartData = performance?.map((p) => p.value * 100.0) || [];

  const chartColor = getChartColor(chartData);

  return [chartData, chartColor];
};

export default function PortfolioItem2(props: PortfolioItemProps) {
  const {
    portfolio: { organization, ...portfolio },
  } = props;

  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  if (!organization) return <></>;

  const [chartData, chartColor] = buildColors(organization);

  return (
    <div className="portifolio-item">
      <div
        className="portifolio-item-org-description"
        onClick={() => setSelectedOrg(organization)}
      >
        <img src={organization.logo} />
        <h3>{organization.name}</h3>
      </div>

      <div>
        <h3 className="portifolio-item-org-amount">
          R$ {toReal(portfolio.amount)}
        </h3>
        {chartData?.[0] && (
          <h4
            style={{ color: chartColor }}
            className="portifolio-item-org-percentage"
            data-tooltip-id="invest-tooltip"
            data-tooltip-html="<div>Percentual de retorno social</br>gerado pelo seu investimento</div>"
          >
            <span>+{chartData?.[0]?.toFixed(2)}%</span>

            <span className="portifolio-item-org-percentage-info">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                color="orange"
                size="lg"
              />
            </span>
          </h4>
        )}

        <Tooltip id="invest-tooltip" />
      </div>

      {selectedOrg && (
        <FullModal closeModal={() => setSelectedOrg(null)}>
          <OrgDetails organization={selectedOrg} />
        </FullModal>
      )}
    </div>
  );
}

type InterfaceColors = "#4caf50" | "#f44336" | "#324d8d";
