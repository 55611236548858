"use client";
import styles from "@/app/page.module.css";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Statement from "./components/StatementList/StatementList";
import { Transaction, listTransactions } from "../../api/transaction";
import { User, getBalance } from "../../api/users";
import Centered from "../../components/Centered";

export default function Wallet() {
  const [transactions, setTransactions] = useState<Transaction[]>();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    listTransactions().then((transactions) => {
      setTransactions(transactions);
    });

    getBalance().then((user) => {
      setUser(user);
    });
  }, []);

  const onRefresh = async () => {
    // console.log("refresh");
  };

  return (
    <>
      <Statement {...{ transactions, user }} />

      {(!transactions || !user) && (
        <Centered>
          <ReactLoading type="spinningBubbles" color="#6bd666" />
        </Centered>
      )}
    </>
  );
}
