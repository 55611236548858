import "./FullModal.scss";
import { Container } from "../Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import styles from "../../app/page.module.css";
import { useCallback, useEffect } from "react";

export default function FullModal(props: IFullModalProps) {
  const handleBackButton = useCallback(() => {
    console.log("POP STATE");
    const modalsItem = localStorage.getItem("modals");
    const currentModals = modalsItem ? JSON.parse(modalsItem) : ([] as Order[]);
    const maxSequence = Math.max.apply(
      null,
      currentModals.map((o: Order) => o.sequence)
    );
    console.log(currentModals);
    console.log(maxSequence);
    console.log(props.order?.sequence);
    console.log(props.closeModal);

    if (maxSequence === props.order?.sequence && props.closeModal) {
      console.log("CLOSE MODAL");
      props.closeModal();
    }
  }, []);

  useEffect(() => {
    console.log("CAL", props);
    if (!props.order) return;

    window.history.pushState({}, "", "");

    window.addEventListener("popstate", handleBackButton);

    const modalsItem = localStorage.getItem("modals");
    const currentModals = modalsItem ? JSON.parse(modalsItem) : [];

    currentModals.push(props.order);

    localStorage.setItem("modals", JSON.stringify(currentModals));

    return function cleanup() {
      const _modalsItem = localStorage.getItem("modals");
      let _currentModals = _modalsItem ? JSON.parse(_modalsItem) : [];

      _currentModals = _currentModals.filter(
        (a: Order) => a.id !== props.order?.id
      );

      localStorage.setItem("modals", JSON.stringify(_currentModals));
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <div className={`full-modal ${props.className || ""}`}>
      <main className={`${styles.main} ${styles.mainWhite}`}>
        <Container fullHeight={true}>
          {props.closeModal && (
            <div className="full-modal-header">
              <button onClick={props.closeModal}>
                <FontAwesomeIcon icon={faClose} size="xl" />
              </button>
            </div>
          )}
          {props.title && <h2>{props.title}</h2>}
          {props.description && (
            <h3 className="full-modal-description">{props.description}</h3>
          )}
          {props.children}
        </Container>
      </main>
    </div>
  );
}

interface IFullModalProps {
  closeModal?: () => void;
  className?: string;
  children: string | JSX.Element | JSX.Element[];
  title?: string;
  description?: string;
  order?: Order;
}

interface Order {
  id: string;
  sequence: number;
}
