import styled from "styled-components";


const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  //height: auto;
  min-height: 200px;
  //width: 100%;
`;

export default Centered;
