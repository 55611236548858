"use client";

import styles from "./container.module.css";
import PullToRefresh from "react-simple-pull-to-refresh";

export function Container(props: any) {
  const { children, onRefresh, fullHeight } = props;

  return (
    <section
      className={[
        styles.container,
        fullHeight ? styles.fullHeightContainer : "",
      ].join(" ")}
    >
      {onRefresh ? (
        <PullToRefresh onRefresh={onRefresh} pullingContent="">
          {children}
        </PullToRefresh>
      ) : (
        children
      )}
    </section>
  );
}
